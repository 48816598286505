<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Создание TOS
            </VToolbarTitle>
          </VToolbar>

          <VDivider />

          <VCardText>
            <VRow>
              <VCol cols="6">
                <VTextField
                  v-model="companyId"
                  label="ID Компании"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="6">
                <VTextarea
                  v-model="text"
                  label="Текст"
                />
              </VCol>
            </VRow>
          </VCardText>

          <VCardActions>
            <VBtn
              color="primary"
              large
              @click="createTosConfig"
            >
              Создать
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'TosCreate',
  inject: ['Names'],
  data() {
    return {
      companyId: '',
      text: '',
    };
  },

  methods: {
    async createTosConfig() {
      try {
        const { companyId, text } = this;

        await this.$di.api.Talent.tosConfigUpdate({ companyId, text });

        this.goToTosConfigEdit(companyId);
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    goToTosConfigEdit(companyId) {
      this.$router.push({
        name: this.Names.R_TALENT_TOS_EDIT,
        params: { companyId },
      });
    },
  },
};
</script>
